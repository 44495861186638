/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useState, useEffect, useContext } from 'react';
import api from '../services/api';
import IUserSession from '../@types/User/IUserSession';
import { IAuthContextData } from '../@types/User/IAuthContextData';
import { feedbackError } from '../functions/MessageFeedback';
import { IClient } from '../@types/Client/IClient';

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<IUserSession | null>(null);
  const [typeUser, setTypeUser] = useState<string>('');
  const [client, setClient] = useState<IClient | null>(null);
  const [id_client, setId_client] = useState<number | null>();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    loadTokenCache();
  }, []);

  async function loadTokenCache() {
    const token = localStorage.getItem('@elevsis-cust:token');

    if (token) {
      const id_user = await getProfile();

      verifyClientView(id_user);
    }

    setloading(false);
  }

  async function getProfile() {
    try {
      const response = await api.get(`users/profile`);

      const { data } = response;

      if (data?.id) {
        setUser(data);

        return data.id;
      } else {
        feedbackError(
          'Ocorreu um erro ao carregar os dados. Tente novamente em alguns instantes e, se o problema persistir, contate o suporte',
        );

        signOut();
      }
    } catch (err) {
      errorLogin();

      signOut();
    }
  }

  function verifyClientView(id_user: string) {
    const IdClient = localStorage.getItem('@elevsis-cust:id_client-view');

    if (IdClient) {
      const IdClient_int = parseInt(IdClient);

      verifyUserPermissionClient(IdClient_int, id_user);
    } else {
      getClientsForThisUser(id_user);
    }
  }

  async function verifyUserPermissionClient(
    IdClient: number,
    id_user: string,
  ): Promise<void> {
    if (!IdClient || !id_user) return;

    try {
      const response = await api.get(`pivotUserClient/client/${IdClient}`);

      const { data } = response;

      if (data.length === 0) {
        feedbackError('Ação não permitida!');

        signOut();
      }

      for (let i = 0; i < data.length; i++) {
        if (data[i].id_user === id_user) {
          if (data[i]?.type === 'admin') {
            setTypeUser('admin');
          } else {
            setTypeUser('default');
          }

          await changeClient(IdClient);
        }

        break;
      }

      return;
    } catch (err) {
      return;
    }
  }

  async function getClientsForThisUser(id_user: string) {
    try {
      const response = await api.get(`pivotUserClient/user/${id_user}`);

      const { data } = response;

      if (data.length > 0) {
        if (data[0]?.type === 'admin') {
          setTypeUser('admin');
        } else {
          setTypeUser('');
        }
        changeClient(data[0].id_client);
      } else {
        changeClient(null);
      }
    } catch (err) {
      return;
    }
  }

  async function changeClient(id_client: number | null) {
    setId_client(id_client);

    getClient(id_client);

    localStorage.setItem('@elevsis-cust:id_client-view', id_client.toString());

    return;
  }

  async function getClient(id_client: number) {
    if (!id_client) return;

    try {
      const response = await api.get(`client/${id_client}`);

      setClient(response.data);
    } catch (err) {
      return;
    }
  }

  const errorLogin = () => {
    feedbackError('Erro ao fazer login');

    signOut();
  };

  async function sign(username: string, password: string) {
    const body = {
      username,
      password,
    };

    const deviceid = {
      headers: {
        deviceid: 'react',
      },
    };

    try {
      const response = await api.post(
        '/users/sessions/bypassword',
        body,
        deviceid,
      );

      const user_api = response.data;

      localStorage.setItem('@elevsis-cust:user', JSON.stringify(user_api.user));

      localStorage.setItem('@elevsis-cust:token', user_api.token);

      localStorage.setItem(
        '@elevsis-cust:refreshToken',
        user_api.refresh_token,
      );

      setUser(user_api.user);

      getClientsForThisUser(user_api.user.id);
    } catch (err) {
      errorLogin();
    }
  }

  async function signOut() {
    try {
      await api.post(`users/sessions/logout`);
    } catch (err) {
      return;
    }

    localStorage.clear();

    setUser(null);

    setClient(null);
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        client,
        id_client,
        verifyUserPermissionClient,
        loading,
        sign,
        signOut,
        typeUser,
        getProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}
