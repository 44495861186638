/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import api from '../../services/api';
import { useAuth } from '../../contexts/Auth';
import { feedbackError } from '../../functions/MessageFeedback';
import RowList from './components/RowList';
import NavBarList from '../../components/NavBarList';
import { IPreventiva } from '../../@types/Preventiva/IPreventiva';

export default function Preventives() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [listPreventives, setListPreventives] = useState<IPreventiva[] | []>(
    [],
  );
  const [page, setpage] = useState(1);
  const [lines_per_page, setLines_per_page] = useState(12);
  const [qtdPages, setQtdPages] = useState<number>();
  const [qtdRegistros, setQtdRegistros] = useState(0);

  const { id_client } = useAuth();

  useEffect(() => {
    getListPreventives();
  }, []);

  useEffect(() => {
    getListPreventives();
  }, [page]);

  function changePage(action: string) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  async function getListPreventives() {
    if (!id_client) return;

    try {
      const response = await api.get(
        `preventiva?page=${page}&lines_per_page=${lines_per_page}&id_cliente=${id_client}`,
      );

      setListPreventives(response.data.data);

      setQtdRegistros(response.data.total);

      let numPages = response.data.total / lines_per_page;

      numPages = Math.ceil(numPages);

      setQtdPages(numPages);
    } catch (err) {
      feedbackError(
        'Erro ao carregar a listagem. Tente novamente ou entre em contato com o suporte',
      );
    }
  }

  return (
    <div className="min-h-full bg-gray-100">
      <SideBar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        active="preventives"
      />

      <div className="lg:pl-64 flex flex-col flex-1">
        <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="flex-1 pb-8">
          <div className="bg-white shadow"></div>

          <div className="mt-4">
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-4 bg-white shadow sm:rounded-md">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Ver</span>
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Elevador
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Início
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Término
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Técnico responsável
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {listPreventives.map(item => (
                    <RowList key={item.id_preventiva} preventive={item} />
                  ))}
                </tbody>
              </table>
              <NavBarList
                page={page}
                qtdPages={qtdPages}
                totalRegisters={qtdRegistros}
                changePage={changePage}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
