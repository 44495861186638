import {
  CheckIcon,
  ThumbUpIcon,
  DotsHorizontalIcon,
} from '@heroicons/react/solid';
import {
  LoadingOutlined,
  CarOutlined,
  ToolOutlined,
  CheckOutlined,
} from '@ant-design/icons';

const EventTypes = {
  created: { icon: ThumbUpIcon, bgColorClass: 'bg-blue-500' },
  going: { icon: CarOutlined, bgColorClass: 'bg-gray-100' },
  attendance: { icon: ToolOutlined, bgColorClass: 'bg-gray-100' },
  completed: { icon: CheckOutlined, bgColorClass: 'bg-gray-100' },
  success: { icon: CheckIcon, bgColorClass: 'bg-green-500' },
  waiting: { icon: DotsHorizontalIcon, bgColorClass: 'bg-blue-500' },
  loading: { icon: LoadingOutlined, bgColorClass: 'bg-white' },
};

export default EventTypes;
