/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IPartReq } from '../../../../../@types/Os/IPartReq';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../../functions/MessageFeedback';
import api from '../../../../../services/api';
import RowPartRequisition from '../RowPartRequisition';
import ModalConfirmApprove from './components/ModalConfirmApprove';

interface IProps {
  id_os: number;
  id_proposal: string;
}

export default function PartRequisition({ id_os, id_proposal }: IProps) {
  const [open, setOpen] = useState(false);
  const [part_req, setPart_req] = useState<IPartReq[] | []>([]);
  const [subTotal, setSubTotal] = useState<string>('');
  const [submeting, setSubmeting] = useState(false);

  useEffect(() => {
    getProposal();
  }, []);

  useEffect(() => {
    somaTotal();
  }, [part_req]);

  async function getProposal() {
    try {
      const response = await api.get(`os/proposal/${id_proposal}`);

      setPart_req(response.data.part_req);
    } catch (err) {
      feedbackError(
        'Não foi possível carregar a proposta. Caso o erro persiste, contate o suporte.',
      );
    }
  }

  async function somaTotal() {
    let vtotal = '0';

    setSubTotal(vtotal);

    part_req?.map(function (req) {
      //Se o valor da req estiver em branco, não entra na soma
      if (req.valor_total) {
        const numero1 = parseFloat(vtotal);
        const numero2 = parseFloat(req.valor_total);

        vtotal = (numero1 + numero2).toFixed(2);

        setSubTotal(vtotal);
      }
    });
  }

  async function approveOrder(note: string): Promise<void> {
    setSubmeting(true);

    const data = {
      id_proposal,
      note,
    };

    try {
      await api.post(`os/proposal/approve`, data);

      feedbackSuccess('Orçamento aprovado com sucesso!');
    } catch (err) {
      feedbackError('Ocorreu um erro. Caso persista, contate o suporte.');
    }

    await getProposal();

    setSubmeting(false);
  }

  return (
    <>
      {part_req?.length > 0 ? (
        <div className="px-0 sm:px-0 lg:px-0">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Requisição de peças
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Foi constatada a necessidade dos itens abaixo para a realização
                do serviço. <br />
                <span className="text-xs text-gray-500 font-extralight">
                  Clique no botão para aceitar a proposta abaixo.
                </span>
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {part_req[0]?.aprovado ? (
                <button
                  type="button"
                  disabled={true}
                  className="inline-flex items-center justify-center rounded-sm border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
                >
                  Aprovado
                </button>
              ) : (
                <button
                  type="button"
                  disabled={submeting}
                  onClick={() => setOpen(true)}
                  className="inline-flex items-center justify-center rounded-sm border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                >
                  Aprovar
                </button>
              )}
            </div>
            <ModalConfirmApprove
              approveOrder={approveOrder}
              open={open}
              setOpen={setOpen}
            />
          </div>
          <div className="-mx-4 mt-4 flex flex-col sm:-mx-6 md:mx-0">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                  >
                    Descrição do item
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Quant.
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Valor unit.
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                  >
                    Total item
                  </th>
                </tr>
              </thead>
              <tbody>
                {part_req?.map(req => (
                  <RowPartRequisition part_req={req} key={req.id_req_peca} />
                ))}
              </tbody>
              <tfoot>
                <tr>
                  <th
                    scope="row"
                    colSpan={3}
                    className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                  >
                    Total
                  </th>
                  <th
                    scope="row"
                    className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                  >
                    Total
                  </th>
                  <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                    R$ {subTotal}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
}
