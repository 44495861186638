import React, { useState } from 'react';
import { PlusIcon } from '@heroicons/react/solid';
import { LoadingOutlined } from '@ant-design/icons';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import { useAuth } from '../../../../contexts/Auth';

interface IProps {
  getList(): void;
}

export default function InputVinculation(props: IProps) {
  const [key, setKey] = useState('');
  const [loading, setLoading] = useState(false);

  const { getList } = props;

  const { user } = useAuth();

  async function handleSubmit() {
    if (!key) return;

    setLoading(true);

    const data = {
      id_user: user.id,
      key,
    };

    try {
      await api.post(`pivotUserClient`, data);

      getList();

      feedbackSuccess('Sucesso');
    } catch (err) {
      if (err.response.status === 404 || err.response.status === 409) {
        if (err.response.data?.message === 'client not found') {
          feedbackError('Código inválido', 3);
        }

        if (err.response.data?.message === 'client already vinculate') {
          feedbackError('Condomínio já vinculado', 3);
        }
      } else {
        feedbackError(
          'Não foi possível vincular. Caso o erro persista, entre em contato com o suporte.',
          3,
        );
      }
    }

    setLoading(false);

    setKey('');
  }

  return (
    <div className="py-4 flex mx-auto w-1/3">
      <label htmlFor="email" className="sr-only">
        Código de vinculação
      </label>
      <input
        type="password"
        name="key"
        id="key"
        value={key}
        onKeyPress={event => {
          if (event.key === 'Enter') {
            handleSubmit();
          }
        }}
        maxLength={30}
        onChange={e => setKey(e.target.value)}
        className="shadow-sm px-2 border border-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block sm:text-sm border-gray-300 rounded-md"
        placeholder="Código de vinculação"
      />
      <button
        disabled={loading}
        onClick={() => handleSubmit()}
        className="inline-flex items-center ml-1 px-6 py-2 border border-blue-600 shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        {loading ? (
          <LoadingOutlined style={{ marginRight: 6 }} />
        ) : (
          <PlusIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
        )}
        Vincular
      </button>
    </div>
  );
}
