import { IAttendance } from '../types/IAttendance';
import { ITypesAttendance } from '../types/ITypesAttendance';

function ResolverTypes(attendance: IAttendance): ITypesAttendance {
  let resume = '';
  let routeTo = '';
  let routeState;

  if (attendance?.id_preventiva) {
    resume = 'Foi realizada a manutenção preventiva';
    routeTo = 'preventive';
    routeState = { id_preventiva: attendance.id_preventiva, mode: 'view' };
  }

  if (attendance?.chamado) {
    resume = 'Atendimento de chamado';
    routeTo = 'order';
    routeState = { id: attendance.id_os };
  }

  if (!attendance?.chamado && attendance?.id_os) {
    resume = `Execução da ordem de serviço ${attendance.id_os}`;
    routeTo = 'order';
    routeState = { id: attendance.id_os };
  }

  return { resume, routeTo, routeState };
}

export default ResolverTypes;
