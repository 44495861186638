/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IPartReq } from '../../../../@types/Os/IPartReq';

interface IProps {
  part_req: IPartReq;
}

export default function RowPartRequisition(props: IProps) {
  const [valor_total, setValor_total] = useState('');

  const { part_req } = props;

  useEffect(() => {
    convertData();
  }, [props]);

  async function convertData() {
    const { valor_total } = part_req;

    const valor_total_float = parseFloat(valor_total);

    const vtotal = valor_total_float.toFixed(2);

    setValor_total(vtotal);
  }

  return (
    <tr className="border-b border-gray-200">
      <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0">
        <div className="font-medium text-gray-900">{part_req.nome_peca}</div>
        <div className="mt-0.5 text-gray-500 sm:hidden">
          {part_req.nome_peca}
        </div>
      </td>
      <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
        {part_req.quantidade}
      </td>
      <td className="hidden py-4 px-3 text-right text-sm text-gray-500 sm:table-cell">
        R$ {part_req.valor_unit ? part_req.valor_unit : 0}
      </td>
      <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
        R$ {valor_total}
      </td>
    </tr>
  );
}
