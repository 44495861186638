/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IAttendance } from './types/IAttendance';
import { useAuth } from '../../../../contexts/Auth';
import api from '../../../../services/api';
import RowSmall from './components/RowSmall';
import RowLarge from './components/RowLarge';

export default function ListAttendances() {
  const [attendances, setAttendances] = useState<[IAttendance] | []>();

  const { id_client } = useAuth();

  useEffect(() => {
    getListAttendances();
  }, [id_client]);

  async function getListAttendances() {
    if (!id_client) return;

    try {
      const response = await api.get(
        `attendances/last?id_cliente=${id_client}`,
      );

      const { data } = response;

      const listRuduzer = data.slice(0, 10);

      setAttendances(listRuduzer);
    } catch (error) {
      return;
    }
  }
  return (
    <>
      <h2 className="max-w-6xl mx-auto mt-8 px-4 text-lg leading-6 font-medium text-gray-900 sm:px-6 lg:px-8">
        Atendimentos recentes
      </h2>

      <div className="shadow sm:hidden">
        <ul
          role="list"
          className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden"
        >
          {attendances?.map((attendance: IAttendance, idx: number) => (
            <RowSmall attendance={attendance} key={idx} />
          ))}
        </ul>
      </div>

      <div className="hidden sm:block">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col mt-2">
            <div className="align-middle min-w-full overflow-x-auto shadow overflow-hidden sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Resumo
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Elevador
                    </th>
                    <th className="hidden px-6 py-3 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider md:block">
                      Técnico
                    </th>
                    <th className="px-6 py-3 bg-gray-50 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Data
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {attendances?.map((attendance: IAttendance, idx: number) => (
                    <RowLarge attendance={attendance} key={idx} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
