/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IAttendance } from '../types/IAttendance';
import convertDateTime from '../../../../../functions/ConvertDateTime';
import { FaWrench } from 'react-icons/fa';
import ResolverTypes from '../functions/ResolverTypes';
import { ITypesAttendance } from '../types/ITypesAttendance';
import { Link } from 'react-router-dom';

interface IProps {
  attendance: IAttendance;
}

export default function RowLarge(props: IProps) {
  const [saida, setSaida] = useState('');
  const [types, setTypes] = useState<ITypesAttendance>();

  const { attendance } = props;

  useEffect(() => {
    convertData();
  }, [props]);

  async function convertData() {
    const types = ResolverTypes(attendance);

    setTypes(types);

    if (attendance.finish) {
      const data_saida = await convertDateTime(attendance.finish);

      setSaida(data_saida);
    }
  }

  return (
    <tr className="bg-white align-baseline">
      <td className="max-w-0 w-full px-6 py-4 whitespace-nowrap text-sm text-gray-900">
        <div className="flex">
          <Link
            to={{
              pathname: `/${types?.routeTo}`,
              state: types?.routeState,
            }}
          >
            <a className="group inline-flex space-x-2 truncate text-sm">
              <FaWrench
                className="flex-shrink-0 h-4 w-4 text-gray-400 group-hover:text-gray-500"
                aria-hidden="true"
              />
              <p className="text-gray-500 truncate group-hover:text-gray-900">
                {types?.resume}
              </p>
            </a>
          </Link>
        </div>
      </td>
      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
        {attendance.apelido_equipamento}
      </td>
      <td className="hidden px-6 py-4 whitespace-nowrap text-sm text-gray-500 md:block">
        {attendance.nome_apelido}
      </td>
      <td className="px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
        {saida}
      </td>
    </tr>
  );
}
