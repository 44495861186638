/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import api from '../../services/api';
import { useAuth } from '../../contexts/Auth';
import { IOs } from '../../@types/Os/IOs';
import { feedbackError } from '../../functions/MessageFeedback';
import RowList from './components/RowList';
import NavBarList from '../../components/NavBarList';

export default function Orders() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [listOs, setListOs] = useState<IOs[] | []>([]);
  const [page, setpage] = useState(1);
  const [lines_per_page, setLines_per_page] = useState(7);
  const [qtdPages, setQtdPages] = useState<number>();
  const [qtdRegistros, setQtdRegistros] = useState(0);

  const { id_client } = useAuth();

  useEffect(() => {
    getListOs();
  }, []);

  useEffect(() => {
    getListOs();
  }, [page]);

  function changePage(action: string) {
    if (action == 'avancar') {
      setpage(page + 1);
    }
    if (action == 'voltar' && page > 1) {
      setpage(page - 1);
    }
  }

  async function getListOs() {
    try {
      const response = await api.get(
        `os?page=${page}&lines_per_page=${lines_per_page}&id_cliente=${id_client}`,
      );

      setListOs(response.data.data);

      setQtdRegistros(response.data.total);

      let numPages = response.data.total / lines_per_page;

      numPages = Math.ceil(numPages);

      setQtdPages(numPages);
    } catch (err) {
      feedbackError(
        'Erro ao carregar a listagem. Tente novamente ou entre em contato com o suporte',
      );
    }
  }

  return (
    <div className="min-h-full bg-gray-100">
      <SideBar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        active="orders"
      />

      <div className="lg:pl-64 flex flex-col flex-1">
        <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="flex-1 pb-8">
          <div className="bg-white shadow"></div>

          <div className="mt-4">
            <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-4 bg-white shadow overflow-hidden sm:rounded-md">
              <ul role="list" className="divide-y divide-gray-200">
                {listOs.map(os => (
                  <RowList os={os} key={os.id_os} />
                ))}
              </ul>
              <NavBarList
                page={page}
                qtdPages={qtdPages}
                totalRegisters={qtdRegistros}
                changePage={changePage}
              />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
