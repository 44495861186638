/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Card from '../Card';
import api from '../../../../services/api';
import { ICard } from '../../@types/ICard';
import { useAuth } from '../../../../contexts/Auth';
import GetPreventiveCurrent from './functions/GetPreventiveCurrent';
import Loading from '../../../../components/Loading';

export default function ListCards() {
  const [cards, setCards] = useState<ICard[] | []>([]);
  const [loading, setLoading] = useState(false);

  const { id_client } = useAuth();

  useEffect(() => {
    void refreshCards();

    setInterval(refreshCards, 60000);
  }, [id_client]);

  async function refreshCards() {
    setLoading(true);

    setCards([]);

    let list = [];

    if (!id_client) return;

    try {
      const preventive = await GetPreventiveCurrent(id_client);

      list = [...list, ...preventive];

      let response = await api.get(
        `os?id_cliente=${id_client}&lines_per_page=99&chamado=true&situacao=liberada&status=atendimento`,
      );

      const chamadosAtendendo = response.data.data;

      chamadosAtendendo.map(
        item =>
          (list = [
            ...list,
            {
              type: 'calledAttending',
              title: 'Chamado',
              description: `${item.nome_apelido} está atendendo`,
              buttonLabel: 'Acompanhar',
              actionButton: 'navigate',
              routeTo: 'order',
              routeState: { id: item.id_os },
            },
          ]),
      );

      response = await api.get(
        `os?id_cliente=${id_client}&lines_per_page=99&chamado=true&situacao=liberada&status=deslocamento`,
      );

      const chamadosDeslocando = response.data.data;

      chamadosDeslocando.map(
        item =>
          (list = [
            ...list,
            {
              type: 'calledGoing',
              title: 'Chamado',
              description: `${item.nome_apelido} está deslocando`,
              buttonLabel: 'Acompanhar',
              actionButton: 'navigate',
              routeTo: 'order',
              routeState: { id: item.id_os },
            },
          ]),
      );

      response = await api.get(
        `os?id_cliente=${id_client}&lines_per_page=99&chamado=true&situacao=liberada&status=agendado`,
      );

      const chamadosAgendados = response.data.data;

      chamadosAgendados.map(
        item =>
          (list = [
            ...list,
            {
              type: 'calledOpen',
              title: 'Chamado',
              description: 'Chamado aberto. Por favor, aguarde',
              buttonLabel: 'Acompanhar',
              actionButton: 'navigate',
              routeTo: 'order',
              routeState: { id: item.id_os },
            },
          ]),
      );

      response = await api.get(
        `os?id_cliente=${id_client}&lines_per_page=99&chamado=true&situacao=liberada&status=aberto`,
      );

      const chamadosAbertos = response.data.data;

      chamadosAbertos.map(
        item =>
          (list = [
            ...list,
            {
              type: 'calledOpen',
              title: 'Chamado',
              description: 'Chamado aberto. Por favor, aguarde',
              buttonLabel: 'Acompanhar',
              actionButton: 'navigate',
              routeTo: 'order',
              routeState: { id: item.id_os },
            },
          ]),
      );

      response = await api.get(
        `os/part_req?id_cliente=${id_client}&status_not=cancelado&validado=true&aprovado=false`,
      );

      const partreqNotApproved = response.data.data;

      partreqNotApproved.map(item =>
        !list.some(e => e?.id === item.id_os)
          ? item.status !== 'concluido'
            ? (list = [
                ...list,
                {
                  type: 'partReq',
                  title: 'Requisição de Peça',
                  description: 'Orçamento aguardando aprovação',
                  buttonLabel: 'Ver',
                  actionButton: 'navigate',
                  routeTo: 'order',
                  routeState: { id: item.id_os },
                  id: item.id_os,
                },
              ])
            : null
          : null,
      );

      setCards(list);
    } catch (err) {
      setLoading(true);

      setTimeout(() => {
        refreshCards();
      }, 1000);
    }

    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          {cards.length > 0 ? (
            <>
              <h2 className="text-lg leading-6 font-medium text-gray-900">
                Fique por dentro
              </h2>
              <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                {cards.map((card, idx) => (
                  <Card
                    type={card.type}
                    title={card.title}
                    description={card.description}
                    buttonLabel={card.buttonLabel}
                    actionButton={card.actionButton}
                    routeTo={card.routeTo}
                    routeState={card.routeState}
                    key={idx}
                  />
                ))}
              </div>
            </>
          ) : null}
        </div>
      )}
    </>
  );
}
