import React from 'react';
import { Upload } from 'antd';
import { MdModeEditOutline } from 'react-icons/md';
import { urlAPI } from '../../../../services/urlAPI';
import { useAuth } from '../../../../contexts/Auth';
import avatar_icon from '../../../../assets/avatar_icon.png';

const { Dragger } = Upload;

export default function AvatarForm() {
  const { user, getProfile } = useAuth();

  const token = localStorage.getItem('@elevsis-cust:token');

  const propsUp = {
    name: 'avatar',
    multiple: false,
    action: `${urlAPI}/users/profile/avatar`,
    headers: { authorization: token },
    async onChange(info) {
      const { status } = info.file;

      if (status !== 'uploading') {
        await getProfile();
      }
    },
  };

  return (
    <>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Foto
            </h3>
          </div>

          <div className="grid grid-cols-3 gap-6">
            <div className="col-span-3">
              <div className="mt-1 flex items-center">
                <span className="inline-block bg-gray-100 rounded-full overflow-hidden h-12 w-12">
                  <img
                    className="inline-block h-12 w-12 rounded-full"
                    src={user?.avatar_url ? user.avatar_url : avatar_icon}
                    alt=""
                  />
                </span>
                <Dragger
                  {...propsUp}
                  style={{
                    height: 0,
                    marginLeft: 10,
                    backgroundColor: '#fff',
                    border: 0,
                  }}
                >
                  <MdModeEditOutline style={{ fontSize: 22 }} />
                </Dragger>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
