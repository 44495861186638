/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ChevronRightIcon } from '@heroicons/react/solid';
import { IAttendance } from '../types/IAttendance';
import convertDateTime from '../../../../../functions/ConvertDateTime';
import { FaWrench } from 'react-icons/fa';
import ResolverTypes from '../functions/ResolverTypes';
import { ITypesAttendance } from '../types/ITypesAttendance';
import { Link } from 'react-router-dom';

interface IProps {
  attendance: IAttendance;
}

export default function RowSmall(props: IProps) {
  const [saida, setSaida] = useState('');
  const [types, setTypes] = useState<ITypesAttendance>();

  const { attendance } = props;

  useEffect(() => {
    convertData();
  }, [props]);

  async function convertData() {
    const types = ResolverTypes(attendance);

    setTypes(types);

    if (attendance.finish) {
      const data_saida = await convertDateTime(attendance.finish);

      setSaida(data_saida);
    }
  }

  return (
    <li>
      <a className="block px-4 py-4 bg-white hover:bg-gray-50">
        <Link
          to={{
            pathname: `/${types?.routeTo}`,
            state: types?.routeState,
          }}
        >
          <span className="flex items-center space-x-4">
            <span className="flex-1 flex space-x-2 truncate">
              <FaWrench
                className="flex-shrink-0 h-4 w-4 text-gray-400"
                aria-hidden="true"
              />
              <span className="flex flex-col text-gray-500 text-sm truncate">
                <span className="truncate">{types?.resume}</span>
                <span>Elevador {attendance.apelido_equipamento}</span>
                <time dateTime={saida}>{saida}</time>
              </span>
            </span>
            <ChevronRightIcon
              className="flex-shrink-0 h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </span>
        </Link>
      </a>
    </li>
  );
}
