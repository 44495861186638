/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { IOs } from '../../../../@types/Os/IOs';
import TLCalled from './TLCalled';
import TLOS from './TLOS';

interface IProps {
  os: IOs;
}

export default function Timeline(props: IProps) {
  const { os } = props;

  return <>{os?.chamado ? <TLCalled os={os} /> : <TLOS os={os} />}</>;
}
