/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../functions/MessageFeedback';
import api from '../../../services/api';

export default function VerifyEmail() {
  const params = new URLSearchParams(window.location.search);

  const token = params.get('token');

  const history = useHistory();

  useEffect(() => {
    checkToken();
  }, [token]);

  async function checkToken() {
    if (!token) return;

    try {
      await api.post(`users/validate/email`, { token });

      feedbackSuccess('E-mail verificado', 5);

      history.push('/dashboard');
    } catch (err) {
      if (err.status === 499) {
        feedbackError('Token expirado. Solicite nova verificação.');
      } else {
        feedbackError(
          'Erro ao confirmar. Se o problema persistir, entre em contato com o suporte.',
          3,
        );
      }
    }
  }

  return <span></span>;
}
