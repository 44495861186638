import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';

import Route from './Route';
import RoutePublic from './RoutePublic';

import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import Orders from '../pages/Orders';
import Order from '../pages/Order';
import SelectClient from '../pages/SelectClient';
import Preventives from '../pages/Preventives';
import Preventive from '../pages/Preventive';
import ProposalPartReq from '../pages/public/ProposalPartReq';
import ForgotPassword from '../pages/public/ForgotPassword';
import ResetPassword from '../pages/public/ResetPassword';
import Account from '../pages/Account';
import VerifyEmail from '../pages/public/VerifyEmail';
import SignUp from '../pages/SignUp';

export default function Routes() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/" exact component={SignIn} />
        <Route path="/signin" component={SignIn} />
        <Route path="/signup" component={SignUp} />
        <Route path="/dashboard" component={Dashboard} isPrivate />
        <Route path="/orders" component={Orders} isPrivate />
        <Route path="/order" component={Order} isPrivate />
        <Route path="/preventives" component={Preventives} isPrivate />
        <Route path="/preventive" component={Preventive} isPrivate />
        <Route path="/selectclient" component={SelectClient} isPrivate />
        <Route path="/account" component={Account} isPrivate />
        <RoutePublic path="/proposta" component={ProposalPartReq} />
        <RoutePublic path="/forgotpassword" component={ForgotPassword} />
        <RoutePublic path="/resetpassword" component={ResetPassword} />
        <RoutePublic path="/verifyemail" component={VerifyEmail} />
      </Switch>
    </BrowserRouter>
  );
}
