import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { MenuAlt1Icon, PlusIcon } from '@heroicons/react/outline';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { SwitchHorizontalIcon } from '@heroicons/react/outline';
import { useAuth } from '../../contexts/Auth';
import { Link } from 'react-router-dom';
import avatar_icon from '../../assets/avatar_icon.png';

interface IProps {
  setSidebarOpen(sidebarOpen: boolean): unknown;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function TopBar(props: IProps | any) {
  const { setSidebarOpen } = props;

  const { user, client, signOut } = useAuth();

  return (
    <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
      <button
        type="button"
        className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Abrir menu</span>
        <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      {/* Search bar */}
      <div className="flex-1 px-4 flex justify-between sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="flex-1 flex">
          <form className="w-full flex md:ml-0" action="#" method="GET">
            <label htmlFor="search-field" className="sr-only">
              Pesquisar
            </label>
            <div className="relative w-full text-gray-400 focus-within:text-gray-600"></div>
          </form>
        </div>
        <div className="ml-4 flex items-center md:ml-6">
          {/* <button
            type="button"
            className="bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <span className="sr-only">Ver Notificações</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
          </button> */}

          {/* Profile dropdown */}
          <Menu as="div" className="ml-3 relative">
            <div>
              <Menu.Button className="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                <img
                  className="h-8 w-8 rounded-full"
                  src={user?.avatar_url ? user.avatar_url : avatar_icon}
                  alt=""
                />
                <span className="hidden ml-3 text-gray-700 text-sm font-medium lg:block">
                  <span className="sr-only">Abrir menu de </span>
                  {user.first_name}
                </span>
                <ChevronDownIcon
                  className="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block"
                  aria-hidden="true"
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={{
                        pathname: '/selectclient',
                      }}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'px-4 py-2 text-sm text-gray-700 flex flex-row justify-between',
                      )}
                    >
                      {client?.id_cliente ? (
                        <>
                          {client.edificio.substring(0, 16)}
                          <SwitchHorizontalIcon
                            className="hidden flex-shrink-0 ml-1 h-4 w-4 lg:block"
                            aria-hidden="true"
                          />
                        </>
                      ) : (
                        <>
                          <span>Vincular Condomínio</span>
                        </>
                      )}
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={{
                        pathname: '/account',
                      }}
                      href="#"
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      )}
                    >
                      Minha Conta
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <a
                      href=""
                      onClick={() => signOut()}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700',
                      )}
                    >
                      Sair
                    </a>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
}
