/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CheckIcon } from '@heroicons/react/outline';
import TopBar from '../../components/TopBar';
import { useAuth } from '../../contexts/Auth';

import { RadioGroup } from '@headlessui/react';
import { IClientsList } from '../../@types/User/IClientsList';
import api from '../../services/api';
import RowList from './components/RowList';
import SideBar from '../../components/SideBar';
import InputVinculation from './components/InputVinculation';
import Loading from '../../components/Loading';

export default function SelectClient() {
  const [selected, setSelected] = useState<number | null>();
  const [clientsList, setClientsList] = useState<IClientsList[] | null>();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user, verifyUserPermissionClient, id_client } = useAuth();

  const history = useHistory();

  useEffect(() => {
    getList();
  }, []);

  async function defineClient(): Promise<void> {
    setLoading(true);

    await verifyUserPermissionClient(selected, user?.id);

    setLoading(false);

    history.push('/dashboard');
  }

  async function getList() {
    if (!user?.id) return;

    try {
      const response = await api.get(`/pivotUserClient/user/${user.id}`);

      setClientsList(response.data);

      setSelected(id_client);
    } catch (err) {
      setTimeout(() => {
        getList();
      }, 1000);
    }
  }

  return (
    <>
      <div className="min-h-full bg-gray-100">
        <SideBar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          active=""
        />

        <div className="lg:pl-64 flex flex-col flex-1">
          <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main className="flex-1 pb-8">
            <div className="bg-white shadow pb-32">
              <div className="px-4 pt-20 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <InputVinculation getList={getList} />

                <RadioGroup value={selected} onChange={setSelected}>
                  <RadioGroup.Label className="sr-only"></RadioGroup.Label>
                  <div className="relative bg-white rounded-md -space-y-px">
                    {clientsList?.map((client, index) => (
                      <RowList
                        client={client}
                        index={index}
                        clientsList={clientsList}
                        setSelected={setSelected}
                        getList={getList}
                        key={client.id}
                      />
                    ))}
                  </div>
                </RadioGroup>

                <div className="mt-3">
                  {selected ? (
                    <button
                      type="button"
                      disabled={loading}
                      onClick={defineClient}
                      className="inline-flex items-center px-8 py-2 border border-transparent shadow-sm text-sm font-medium rounded text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      {loading ? (
                        <Loading />
                      ) : (
                        <>
                          <CheckIcon
                            className="-ml-0.5 mr-2 h-4 w-4"
                            aria-hidden="true"
                          />
                          Salvar
                        </>
                      )}
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
