/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IPartReq } from '../../../../@types/Os/IPartReq';
import { useAuth } from '../../../../contexts/Auth';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import RowPartRequisition from '../RowPartRequisition';
import ModalConfirmApprove from './components/ModalConfirmApprove';

interface IProps {
  id_os: number;
}

export default function PartRequisition({ id_os }: IProps) {
  const [open, setOpen] = useState(false);
  const [listPartReq, setListPartReq] = useState<[IPartReq] | []>([]);
  const [subTotal, setSubTotal] = useState<string>('');
  const [submeting, setSubmeting] = useState(false);

  const { typeUser } = useAuth();

  useEffect(() => {
    getListPartReq(id_os);
  }, [id_os]);

  useEffect(() => {
    somaTotal();
  }, [listPartReq]);

  async function getListPartReq(id_os: number) {
    if (!id_os) return;

    try {
      const response = await api.get(`os/part_req?id_os=${id_os}`);

      setListPartReq(response.data.data);
    } catch (err) {
      setTimeout(async () => {
        await getListPartReq(id_os);
      }, 2000);
    }
  }

  async function somaTotal() {
    let vtotal = '0';

    setSubTotal(vtotal);

    listPartReq.map(function (part_req) {
      //Se o valor da req estiver em branco, não entra na soma
      if (part_req.valor_total) {
        const numero1 = parseFloat(vtotal);
        const numero2 = parseFloat(part_req.valor_total);

        vtotal = (numero1 + numero2).toFixed(2);

        setSubTotal(vtotal);
      }
    });
  }

  async function approveOrder(note: string): Promise<void> {
    setSubmeting(true);

    const data = {
      id_os,
      note,
    };

    try {
      await api.post(`os/part_req/approve`, data);

      feedbackSuccess('Orçamento aprovado com sucesso!');

      await getListPartReq(id_os);
    } catch (err) {
      feedbackError('Ocorreu um erro. Caso persista, contate o suporte.');
    }

    setSubmeting(false);
  }

  return (
    <>
      {listPartReq.length > 0 ? (
        <div className="px-0 sm:px-0 lg:px-0">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-xl font-semibold text-gray-900">
                Requisição de peças
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Foi constatada a necessidade dos itens abaixo para a realização
                do serviço. <br />
                <span className="text-xs text-gray-500 font-extralight">
                  {typeUser === 'admin'
                    ? 'Clique no botão para aceitar a proposta abaixo.'
                    : null}
                </span>
              </p>
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              {listPartReq[0].aprovado ? (
                <button
                  type="button"
                  disabled={true}
                  className="inline-flex items-center justify-center rounded-sm border border-transparent bg-gray-300 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
                >
                  Aprovado
                </button>
              ) : typeUser === 'admin' ? (
                <button
                  type="button"
                  disabled={submeting}
                  onClick={() => setOpen(true)}
                  className="inline-flex items-center justify-center rounded-sm border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:w-auto"
                >
                  Aprovar
                </button>
              ) : null}
            </div>
            <ModalConfirmApprove
              approveOrder={approveOrder}
              open={open}
              setOpen={setOpen}
            />
          </div>
          <div className="-mx-4 mt-4 flex flex-col sm:-mx-6 md:mx-0">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
                  >
                    Descrição do item
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Quant.
                  </th>
                  <th
                    scope="col"
                    className="hidden py-3.5 px-3 text-right text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Valor unit.
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
                  >
                    Total item
                  </th>
                </tr>
              </thead>
              <tbody>
                {listPartReq.map(part_req => (
                  <RowPartRequisition
                    part_req={part_req}
                    key={part_req.id_req_peca}
                  />
                ))}
              </tbody>
              <tfoot>
                {/* <tr>
                  <th
                    scope="row"
                    colSpan={3}
                    className="hidden pl-6 pr-3 pt-6 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
                  >
                    Subtotal
                  </th>
                  <th
                    scope="row"
                    className="pl-4 pr-3 pt-6 text-left text-sm font-normal text-gray-500 sm:hidden"
                  >
                    Subtotal
                  </th>
                  <td className="pl-3 pr-4 pt-6 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                    R$ {subTotal}
                  </td>
                </tr> */}
                {/* <tr>
              <th
                scope="row"
                colSpan={3}
                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-normal text-gray-500 sm:table-cell md:pl-0"
              >
                Mão de obra
              </th>
              <th
                scope="row"
                className="pl-4 pr-3 pt-4 text-left text-sm font-normal text-gray-500 sm:hidden"
              >
                Mão de obra
              </th>
              <td className="pl-3 pr-4 pt-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                R$ 0,00
              </td>
            </tr> */}
                <tr>
                  <th
                    scope="row"
                    colSpan={3}
                    className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
                  >
                    Total
                  </th>
                  <th
                    scope="row"
                    className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
                  >
                    Total
                  </th>
                  <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                    R$ {subTotal}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
}
