import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import {
  HomeIcon,
  ClockIcon,
  XIcon,
  CalendarIcon,
} from '@heroicons/react/outline';
import logo from '../../assets/logo.svg';
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth';

interface IProps {
  sidebarOpen: boolean;
  setSidebarOpen(sidebarOpen: boolean): unknown;
  active: string;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function SideBar(props: IProps) {
  const { sidebarOpen, setSidebarOpen, active } = props;

  const { user, client } = useAuth();

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-indigo-700">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Fechar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex flex-shrink-0 justify-center items-center py-1 px-4 bg-white">
                <img
                  className="w-40 flex justify-center"
                  src={logo}
                  alt="logo mini"
                />
              </div>
              <nav
                className="mt-5 flex-shrink-0 h-full divide-y divide-indigo-800 overflow-y-auto"
                aria-label="Sidebar"
              >
                <div className="px-2 space-y-1">
                  <Link
                    to={{
                      pathname: '/dashboard',
                    }}
                  >
                    <a
                      className={classNames(
                        'dashboard' === active
                          ? 'bg-indigo-800 text-white hover:text-white'
                          : 'text-indigo-100 hover:text-white hover:bg-indigo-600',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                      )}
                      aria-current={'dashboard' === active ? 'page' : undefined}
                    >
                      <HomeIcon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"
                        aria-hidden="true"
                      />
                      Início
                    </a>
                  </Link>
                  <Link
                    to={{
                      pathname: '/orders',
                    }}
                  >
                    <a
                      className={classNames(
                        'orders' === active
                          ? 'bg-indigo-800 text-white hover:text-white'
                          : 'text-indigo-100 hover:text-white hover:bg-indigo-600',
                        'group flex items-center px-2 py-2 text-base text-white font-medium rounded-md',
                      )}
                      aria-current={'orders' === active ? 'page' : undefined}
                    >
                      <ClockIcon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"
                        aria-hidden="true"
                      />
                      Ordens de Serviço
                    </a>
                  </Link>
                  <Link
                    to={{
                      pathname: '/preventives',
                    }}
                  >
                    <a
                      className={classNames(
                        'preventives' === active
                          ? 'bg-indigo-800 text-white hover:text-white'
                          : 'text-indigo-100 hover:text-white hover:bg-indigo-600',
                        'group flex items-center px-2 py-2 text-base font-medium rounded-md',
                      )}
                      aria-current={
                        'preventives' === active ? 'page' : undefined
                      }
                    >
                      <CalendarIcon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"
                        aria-hidden="true"
                      />
                      Manut. Preventiva
                    </a>
                  </Link>
                </div>
                {/* <div className="mt-6 pt-6">
                  <div className="px-2 space-y-1">
                    <Link
                      to={{
                        pathname: '/config',
                      }}
                    >
                      <a className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-indigo-100 hover:text-white hover:bg-indigo-600">
                        <CogIcon
                          className="mr-4 h-6 w-6 text-indigo-200"
                          aria-hidden="true"
                        />
                        Ajustes
                      </a>
                    </Link>

                    <Link
                      to={{
                        pathname: '/help',
                      }}
                    >
                      <a className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-indigo-100 hover:text-white hover:bg-indigo-600">
                        <QuestionMarkCircleIcon
                          className="mr-4 h-6 w-6 text-indigo-200"
                          aria-hidden="true"
                        />
                        Ajuda
                      </a>
                    </Link>
                  </div>
                </div> */}
              </nav>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow bg-indigo-700 pt-1 pb-2 px-2 overflow-y-auto">
          <div className="flex items-center justify-center flex-shrink-0 rounded-sm bg-white px-4">
            <img className="w-36 flex justify-center" src={logo} alt="logo" />
          </div>
          <nav
            className="mt-5 flex-1 flex flex-col divide-y divide-indigo-800 overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="px-2 space-y-1">
              <Link
                to={{
                  pathname: '/dashboard',
                }}
              >
                <a
                  className={classNames(
                    'dashboard' === active
                      ? 'bg-indigo-800 text-white hover:text-white'
                      : 'text-indigo-100 hover:text-white hover:text-white hover:bg-indigo-600',
                    'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md',
                  )}
                  aria-current={'dashboard' === active ? 'page' : undefined}
                >
                  <HomeIcon
                    className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"
                    aria-hidden="true"
                  />
                  Início
                </a>
              </Link>
              {client?.id_cliente ? (
                <>
                  <Link
                    to={{
                      pathname: '/orders',
                    }}
                  >
                    <a
                      className={classNames(
                        'orders' === active
                          ? 'bg-indigo-800 text-white hover:text-white'
                          : 'text-indigo-100 hover:text-white hover:text-white hover:bg-indigo-600',
                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md',
                      )}
                      aria-current={'orders' === active ? 'page' : undefined}
                    >
                      <ClockIcon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"
                        aria-hidden="true"
                      />
                      Ordens de Serviço
                    </a>
                  </Link>
                  <Link
                    to={{
                      pathname: '/preventives',
                    }}
                  >
                    <a
                      className={classNames(
                        'preventives' === active
                          ? 'bg-indigo-800 text-white hover:text-white'
                          : 'text-indigo-100 hover:text-white hover:text-white hover:bg-indigo-600',
                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md',
                      )}
                      aria-current={
                        'preventives' === active ? 'page' : undefined
                      }
                    >
                      <CalendarIcon
                        className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"
                        aria-hidden="true"
                      />
                      Manut. Preventiva
                    </a>
                  </Link>
                </>
              ) : null}
            </div>
            {/* <div className="mt-6 pt-6">
              <div className="px-2 space-y-1">
                <Link
                  to={{
                    pathname: '/config',
                  }}
                >
                  <a className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-indigo-100 hover:text-white hover:bg-indigo-600">
                    <CogIcon
                      className="mr-4 h-6 w-6 text-indigo-200"
                      aria-hidden="true"
                    />
                    Ajustes
                  </a>
                </Link>
                <Link
                  to={{
                    pathname: '/help',
                  }}
                >
                  <a className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-indigo-100 hover:text-white hover:bg-indigo-600">
                    <QuestionMarkCircleIcon
                      className="mr-4 h-6 w-6 text-indigo-200"
                      aria-hidden="true"
                    />
                    Ajuda
                  </a>
                </Link>
              </div>
            </div> */}
          </nav>
        </div>
      </div>
    </>
  );
}
