/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../functions/MessageFeedback';
import api from '../../../services/api';
import logoImg from '../../../assets/logo.svg';
import { Link, useHistory } from 'react-router-dom';
import ValidateForcePassword from './components/ValidateForcePassword';

export default function ResetPassword() {
  const [password1, setPassword1] = useState<string>('');
  const [password2, setPassword2] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [forcePasswordCheck, setForcePasswordCheck] = useState(false);

  const history = useHistory();

  const params = new URLSearchParams(window.location.search);

  const token = params.get('token');

  async function handleSubmit() {
    if (!token) {
      feedbackError('Não permitido');
      return;
    }

    if (!password1) {
      feedbackError(
        'Você precisa criar uma nova senha, ou clique em "Lembrei a senha" para realizar login',
      );
      return;
    }

    if (password1 !== password2) {
      feedbackError('As senhas digitadas não coincidem');
      return;
    }

    if (!forcePasswordCheck) {
      feedbackError('Senha pouco segura');
      return;
    }

    setLoading(true);

    const data = {
      password: password1,
      token,
    };

    try {
      const response = await api.post(`users/password/reset`, data);

      if (response.status === 204) {
        feedbackSuccess('Senha alterada com sucesso');

        history.push('/');
      }
    } catch (err) {
      feedbackError(
        'Erro ao redefinir sua senha. Se o problema persistir, entre em contato com o suporte.',
      );
    }

    setLoading(false);
  }

  return (
    <div className="h-screen bg-white w-3/3 m-auto rounded-md flex flex-col justify-center py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
        <img className="mx-auto h-32 w-auto flex" src={logoImg} alt="Logo" />
        <p className="mt-1 text-center text-base text-gray-500">
          Criando uma nova senha
        </p>
      </div>

      <div className="mt-1 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <div className="space-y-6">
            <div>
              <label
                htmlFor="password1"
                className="block text-sm font-medium text-gray-700"
              >
                Nova Senha
              </label>
              <div className="mt-1">
                <input
                  id="password1"
                  name="password1"
                  type="password"
                  autoComplete="nope"
                  onChange={e => setPassword1(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password2"
                className="block text-sm font-medium text-gray-700"
              >
                Repita a Senha
              </label>
              <div className="mt-1">
                <input
                  id="password2"
                  name="password2"
                  type="password"
                  autoComplete="nope"
                  onChange={e => setPassword2(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>

            <ValidateForcePassword
              password={password1}
              setForcePasswordCheck={setForcePasswordCheck}
            />

            <div className="flex items-center justify-end">
              <div className="text-sm">
                <Link
                  to={{
                    pathname: '/signin',
                  }}
                >
                  <a
                    href="#"
                    className="font-medium text-blue-900 hover:text-blue-600"
                  >
                    Lembrei a senha
                  </a>
                </Link>
              </div>
            </div>

            <div>
              <button
                disabled={loading}
                onClick={() => handleSubmit()}
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
              >
                Redefinir
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
