/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import { useLocation } from 'react-router-dom';
import api from '../../services/api';
import convertDateTime from '../../functions/ConvertDateTime';
import { useAuth } from '../../contexts/Auth';
import { IPreventiva } from '../../@types/Preventiva/IPreventiva';

interface IState {
  state: {
    id_preventiva: number;
    mode: string;
  };
}

export default function Preventive() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [preventive, setPreventive] = useState<IPreventiva>();
  const [start, setStart] = useState('');
  const [finish, setFinish] = useState('');

  const location: IState = useLocation();

  const { client } = useAuth();

  useEffect(() => {
    const { id_preventiva } = location.state;

    getPreventive(id_preventiva);
  }, [location.state]);

  async function getPreventive(id_preventiva: number) {
    if (!id_preventiva) return;

    try {
      const response = await api.get(`preventiva/${id_preventiva}`);

      setPreventive(response.data);

      convertData(response.data);
    } catch (err) {
      setTimeout(() => {
        getPreventive(id_preventiva);
      }, 2000);
    }
  }

  async function convertData(preventiva: IPreventiva): Promise<void> {
    const start = await convertDateTime(preventiva.start);
    setStart(start);

    const finish = await convertDateTime(preventiva.finish);
    setFinish(finish);
  }

  return (
    <div className="min-h-full bg-gray-100">
      <SideBar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        active="preventives"
      />

      <div className="lg:pl-64 flex flex-col flex-1">
        <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="flex-1 pb-8">
          <div className="max-w-7xl mx-auto px-2 sm:px-4 lg:px-4 mt-4 bg-white shadow overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 sm:px-6">
              <h3 className="leading-6 mb-6 text-2xl font-normal text-gray-900">
                Registro de Manutenção Preventiva
              </h3>
              <h3 className="text-lg leading-4 font-normal text-gray-600">
                {client.edificio}
              </h3>
              <p className="mt-0 max-w-2xl text-sm text-gray-500">
                {client.logradouro}, {client.numero} {client.bairro} -{' '}
                {client.cidade} - {client.estado}
              </p>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Status</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {preventive?.status === 'concluida' ? (
                      <span className="inline-flex rounded bg-green-100 px-2 capitalize text-xs font-semibold leading-5 text-green-800">
                        {preventive?.status}
                      </span>
                    ) : (
                      <span className="inline-flex rounded bg-yellow-100 px-2 capitalize text-xs font-semibold leading-5 text-yellow-800">
                        {preventive?.status}
                      </span>
                    )}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Elevador
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {preventive?.apelido_equipamento}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Início</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {start}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Término</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {finish}
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Técnico responsável
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {preventive?.nome_apelido}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Cliente responsável
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {preventive?.nome_cliente_responsavel}
                  </dd>
                </div>
                {preventive?.assign?.assign_cliente ? (
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Assinatura do cliente responsável
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                      <img
                        src={`data:image/png;base64,${preventive.assign.assign_cliente}`}
                      />
                    </dd>
                  </div>
                ) : null}
              </dl>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
