/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { MdElevator, MdPermPhoneMsg } from 'react-icons/md';
import { FaClock } from 'react-icons/fa';
import { IOs } from '../../../../@types/Os/IOs';
import convertDateTime from '../../../../functions/ConvertDateTime';
import { Link } from 'react-router-dom';

interface IProps {
  os: IOs;
}

export default function RowList(props: IProps) {
  const [data_criacao, setData_criacao] = useState('');
  const [data_conclusao, setData_conclusao] = useState('');

  const { os } = props;

  useEffect(() => {
    convertData();
  }, [props]);

  async function convertData() {
    const data_criacao = await convertDateTime(os.created_at);

    setData_criacao(data_criacao);

    if (os.data_saida) {
      const data_conclusao = await convertDateTime(os.data_saida);

      setData_conclusao(data_conclusao);
    }
  }

  return (
    <li key={os.id_os}>
      <Link
        to={{
          pathname: '/order',
          state: { id: os.id_os },
        }}
      >
        <a href="#" className="block hover:bg-gray-50">
          <div className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
              <p className="text-sm font-normal w-3/5 text-gray-600 ">
                {os.chamado ? (
                  <span className="font-semibold">
                    <span className="text-gray-800">Chamado</span> -{' '}
                    <span className="font-light">
                      {os.descricao.length > 80
                        ? `${os.descricao.substring(80, 0)} ...`
                        : os.descricao}
                    </span>
                  </span>
                ) : (
                  <span className="font-semibold">
                    Ordem de Serviço {os.id_os}
                  </span>
                )}
              </p>
              <div className="ml-2 flex-shrink-0 flex">
                {os.status === 'concluido' ? (
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-900">
                    Concluído em {data_conclusao}
                  </p>
                ) : null}

                {os.status === 'cancelado' ? (
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                    Cancelado
                  </p>
                ) : null}

                {os.status === 'agendado' || os.status === 'aberto' ? (
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    Aberto
                  </p>
                ) : null}

                {os.status === 'deslocamento' ? (
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    Técnico em deslocamento
                  </p>
                ) : null}

                {os.status === 'atendimento' ? (
                  <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                    Técnico em atendimento
                  </p>
                ) : null}
              </div>
            </div>
            <div className="mt-1 sm:flex sm:justify-between">
              <div className="sm:flex">
                <p className="flex items-center text-sm text-gray-500">
                  <MdElevator
                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  Elevador
                  <span className="ml-1 font-semibold">
                    {os.apelido_equipamento}
                  </span>
                </p>
                <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                  {os.chamado ? (
                    <>
                      <MdPermPhoneMsg
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      Solicitado por
                      <span className="ml-1 font-semibold">
                        {os.solicitante}
                      </span>
                    </>
                  ) : null}
                </p>
              </div>
              <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <FaClock
                  className="flex-shrink-0 mr-1.5 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
                Aberto em
                <time className="ml-1 font-semibold">{data_criacao}</time>
              </div>
            </div>
          </div>
        </a>
      </Link>
    </li>
  );
}
