import { IExtraService } from '../../../../../@types/ExtraService/IExtraService';
import ListPreventiveCurrent from '../../../../../functions/ListPreventiveCurrent';

async function GetPreventiveCurrent(id_client: number) {
  const data = await ListPreventiveCurrent(id_client);

  if (!data || data.length === 0) return [];

  let list = [];

  if (data.length === 1) {
    if (data[0].attendance_start && !data[0].attendance_finish) {
      list = [
        ...list,
        {
          type: 'preventiveAttending',
          icon: 'clipboard-check',
          title: 'Manut. Preventiva',
          description: 'Manutenção preventiva em andamento',
          buttonLabel: '',
          actionButton: null,
          routeTo: null,
          routeState: { id: null },
        },
      ];

      return list;
    }

    if (data[0].displacement_start && !data[0].displacement_finish) {
      list = [
        ...list,
        {
          type: 'preventiveGoing',
          icon: 'map-marker-distance',
          title: 'Manut. Preventiva',
          description: 'Técnico a caminho para realizar Manut. Preventiva',
          buttonLabel: '',
          actionButton: null,
          routeTo: null,
          routeState: { id: null },
        },
      ];

      return list;
    }
  } else {
    list = [
      {
        type: 'preventiveGoing',
        icon: 'map-marker-distance',
        title: 'Manut. Preventiva',
        description: 'Técnico a caminho para realizar Manut. Preventiva',
        buttonLabel: '',
        actionButton: null,
        routeTo: null,
        routeState: { id: null },
      },
    ];

    const promises = data.map((item: IExtraService) => {
      if (item.attendance_start && !item.attendance_finish) {
        list = [
          {
            type: 'preventiveAttending',
            icon: 'clipboard-check',
            title: 'Manut. Preventiva',
            description: 'Manutenção preventiva em andamento',
            buttonLabel: '',
            actionButton: null,
            routeTo: null,
            routeState: { id: null },
          },
        ];
      }
    });

    await Promise.all(promises);

    return list;
  }
}

export default GetPreventiveCurrent;
