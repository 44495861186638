import React, { useEffect, useState } from 'react';
import logoImg from '../../assets/logo.svg';
import {
  feedbackError,
  feedbackSuccess,
} from '../../functions/MessageFeedback';
import Loading from '../../components/Loading';
import { Link, useHistory } from 'react-router-dom';
import ValidateForcePassword from '../public/ResetPassword/components/ValidateForcePassword';
import api from '../../services/api';

export default function SignUp() {
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLast_name] = useState('');
  const [cpf, setCpf] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [forcePasswordCheck, setForcePasswordCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function handleRegister() {
    if (!email || !first_name || !last_name || !cpf) {
      feedbackError('Todos os campos são obrigatórios', 3);

      return;
    }

    if (!password1 || !forcePasswordCheck) {
      feedbackError('A senha escolhida é muito fraca', 3);

      return;
    }

    if (password1 !== password2) {
      feedbackError('As senhas digitadas não coincidem', 3);

      return;
    }

    setLoading(true);

    const data = {
      first_name,
      last_name,
      cpf,
      email,
      password: password1,
    };

    try {
      await api.post(`users/user`, data);

      feedbackSuccess(
        'Usuário cadastrado com sucesso! Acesse o seu e-mail e siga as instruções para confirmá-lo.',
        5,
      );

      history.push('/signin');
    } catch (err) {
      if (err.response.status === 409) {
        if (err.response.data?.message === 'email already exists') {
          feedbackError('E-mail já cadastrado', 3);
        }

        if (err.response.data?.message === 'email already exists') {
          feedbackError('CPF já cadastrado', 3);
        }
      } else {
        feedbackError(
          'Não foi possível cadastrar. Caso o erro persista, entre em contato com o suporte.',
          3,
        );
      }
    }

    setLoading(false);
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="h-screen bg-white mt-10 w-3/3 m-auto rounded-md flex flex-col justify-center py-8 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
            <img
              className="mx-auto h-32 w-auto flex"
              src={logoImg}
              alt="Logo"
            />
            <h2 className="mt-1 text-center text-3xl font-extrabold text-gray-700">
              Novo Cadastro
            </h2>
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-lg">
            <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-6">
              <div className="space-y-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="first_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Nome
                    </label>
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      autoComplete="first_name"
                      onChange={e => setFirst_name(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="last_name"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Sobrenome
                    </label>
                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      autoComplete="last_name"
                      onChange={e => setLast_name(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="cpf"
                      className="block text-sm font-medium text-gray-700"
                    >
                      CPF
                    </label>
                    <input
                      type="text"
                      name="cpf"
                      id="cpf"
                      value={cpf}
                      placeholder="Somente números"
                      maxLength={11}
                      autoComplete="cpf"
                      onChange={e =>
                        setCpf(e.target.value.replace(/[^\d\s-/]/g, ''))
                      }
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      E-mail
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      autoComplete="email"
                      onChange={e => setEmail(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="password1"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Senha
                    </label>
                    <input
                      type="password"
                      name="password1"
                      id="password1"
                      onChange={e => setPassword1(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="password2"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Confirme a Senha
                    </label>
                    <input
                      type="password"
                      name="password2"
                      id="password2"
                      onChange={e => setPassword2(e.target.value)}
                      className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>
                {password1 ? (
                  <ValidateForcePassword
                    password={password1}
                    setForcePasswordCheck={setForcePasswordCheck}
                  />
                ) : null}

                <div>
                  <button
                    disabled={loading}
                    onClick={() => handleRegister()}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  >
                    Cadastrar
                  </button>
                </div>

                <div>
                  <Link
                    to={{
                      pathname: '/signin',
                    }}
                  >
                    <button className="w-full flex justify-center py-2 px-4 border border-blue-900 rounded-md text-sm font-light text-blue-900 bg-white hover:text-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                      Já Possuo Cadastro
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
