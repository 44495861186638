/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import api from '../../../../services/api';
import { ITimeline } from './@types/ITimeline';
import EventTypes from './@types/EventTypes';
import { IDataAttendance } from './@types/IDataAttendance';
import { IProps } from './@types/IProps';
import { format, isValid } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function TLCalled(props: IProps) {
  const [timeline, setTimeline] = useState<ITimeline[]>([]);

  const { os } = props;

  useEffect(() => {
    getAttendances();
  }, [props]);

  async function getAttendances() {
    if (!os?.id_os) return;

    try {
      const response = await api.get(`os/attendance?id_os=${os.id_os}`);

      tlGenerate(response.data);
    } catch (err) {
      setTimeout(() => {
        getAttendances();
      }, 2000);
    }
  }

  async function tlGenerate(data: IDataAttendance) {
    const list = data.data;

    let timelineCalled = [];

    const creationDate = format(new Date(os.created_at), 'dd/MM/yy - hh:mm', {
      locale: ptLocale,
    });

    timelineCalled = [
      {
        id: 1,
        type: EventTypes.created,
        title: 'Chamado registrado',
        details: `Solicitado por ${os.solicitante}`,
        datetime: creationDate,
      },
    ];

    if (data.total > 0) {
      list.sort(function (a, b) {
        if (a.id_atendimento < b.id_atendimento) {
          return -1;
        } else {
          return 1;
        }
      });

      list.map((item: any, itemIdx) => {
        const dateDisplacement = format(
          new Date(item.data_deslocamento),
          'dd/MM/yy - hh:mm',
          {
            locale: ptLocale,
          },
        );

        const dateStart = isValid(new Date(item.data_chegada))
          ? format(new Date(item.data_chegada), 'dd/MM/yy - hh:mm', {
              locale: ptLocale,
            })
          : '';

        const dateEnd = isValid(new Date(item.data_saida))
          ? format(new Date(item.data_saida), 'dd/MM/yy - hh:mm', {
              locale: ptLocale,
            })
          : '';

        if (dateDisplacement) {
          timelineCalled = [
            ...timelineCalled,
            {
              id: itemIdx + 2,
              type: EventTypes.going,
              title: 'Em deslocamento até o local',
              details: `Técnico ${item.nome_apelido}`,
              datetime: dateDisplacement,
            },
          ];
        }

        if (dateStart) {
          timelineCalled = [
            ...timelineCalled,
            {
              id: itemIdx + 3,
              type: EventTypes.attendance,
              title: 'Em atendimento',
              details: `Técnico ${item.nome_apelido}`,
              datetime: dateStart,
            },
          ];
        }

        if (item.status === 'concluido') {
          timelineCalled = [
            ...timelineCalled,
            {
              id: itemIdx + 4,
              type: EventTypes.completed,
              title: 'Atendimento finalizado',
              details: `Técnico ${item.nome_apelido}`,
              datetime: dateEnd,
            },
          ];
        }

        if (itemIdx + 1 < data.total) {
          timelineCalled = [
            ...timelineCalled,
            {
              id: itemIdx + 5,
              type: EventTypes.waiting,
              title: 'Chamado pendente',
              details: `Aguardando para retornar`,
              datetime: dateEnd,
            },
          ];
        }

        if (itemIdx + 1 === data.total && os.status === 'concluido') {
          timelineCalled = [
            ...timelineCalled,
            {
              id: itemIdx + 6,
              type: EventTypes.success,
              title: 'Chamado concluído',
              details: ``,
              datetime: dateEnd,
            },
          ];
        }

        setTimeline(timelineCalled);
      });
    }
  }

  return (
    <section
      aria-labelledby="timeline-title"
      className="lg:col-start-3 lg:col-span-1"
    >
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          Linha do tempo
        </h2>

        <div className="mt-6 flow-root">
          <ul role="list" className="-mb-8">
            {timeline.map((item, itemIdx) => (
              <li key={item.id}>
                <div className="relative pb-6">
                  {itemIdx !== timeline.length - 1 ? (
                    <span
                      className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                      aria-hidden="true"
                    />
                  ) : null}
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          item.type.bgColorClass,
                          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
                        )}
                      >
                        <item.type.icon
                          style={{ fontSize: 20 }}
                          className="w-5 h-5 text-white"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div className="flex flex-col">
                        <span className="text-sm text-gray-600">
                          {item.title}
                        </span>
                        <span className="text-xs text-gray-400">
                          {item.details}
                        </span>
                      </div>
                      <div className="text-right text-xs whitespace-nowrap text-gray-400">
                        {item.datetime}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
