/* eslint-disable prettier/prettier */
import React from 'react';
import { ClockIcon, ExclamationIcon } from '@heroicons/react/outline';
import { ICard } from '../../@types/ICard';
import { MdOutlineHandyman, MdOutlineNearMe } from 'react-icons/md';
import { Link } from 'react-router-dom';

export default function Card(props: ICard) {
  return (
    <div className="bg-white overflow-hidden shadow rounded-lg">
      <div className="p-5">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            {props.type === 'calledAttending' || props.type === 'preventiveAttending' ?
              <>
              <MdOutlineHandyman
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
              </>
             : null}
            {props.type === 'calledGoing' || props.type === 'preventiveGoing' ?
            <>
              <MdOutlineNearMe
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              />
            </> : null}
            {props.type === 'calledOpen' ?
            <>
            <ClockIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </> : null}
            {props.type === 'partReq' ?
            <>
            <ExclamationIcon className="h-6 w-6 text-gray-400" aria-hidden="true" />
            </> : null}
          </div>
          <div className="ml-5 w-0 flex-1">
            <dl>
              <dt className="mb-2=1 text-sm font-medium text-gray-500 truncate">
                {props.title}
              </dt>
              <dd>
                <div className="text-xl font-medium text-gray-900">
                  {props?.description}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
      {props?.buttonLabel ? (
        <div className="bg-gray-30 px-5 py-3">
          <div className="text-sm flex mb-1 justify-center">
            {props?.actionButton === 'navigate' ?
              <Link
              to={{
                pathname: `/${props.routeTo}`,
                state: props.routeState,
              }}>
              <span
                className="px-3 py-2 font-medium bg-indigo-500 rounded text-white hover:bg-indigo-700"
              >
                {props.buttonLabel}
              </span>
            </Link>
            :
            <a
              href={props.actionButton}
              className="p-2 font-medium bg-indigo-500 rounded text-white hover:bg-indigo-700"
            >
              {props.buttonLabel}
            </a>
}
          </div>
        </div>
      ) : null}
    </div>
  );
}
