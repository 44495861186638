/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import convertDateTime from '../../../../functions/ConvertDateTime';
import { Link } from 'react-router-dom';
import { IListPreventiva } from '../../../../@types/Preventiva/IListPreventiva';

interface IProps {
  preventive: IListPreventiva;
}

export default function RowList(props: IProps) {
  const [start, setStart] = useState('');
  const [finish, setFinish] = useState('');

  const { preventive } = props;

  useEffect(() => {
    convertData(preventive);
  }, [props]);

  async function convertData(preventive: IListPreventiva): Promise<void> {
    const start = await convertDateTime(preventive.start);
    setStart(start);

    const finish = await convertDateTime(preventive.finish);
    setFinish(finish);
  }

  return (
    <tr key={preventive.id_preventiva}>
      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <Link
          to={{
            pathname: '/preventive',
            state: { id_preventiva: preventive.id_preventiva, mode: 'view' },
          }}
        >
          <button
            type="button"
            className="inline-flex items-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white bg-gray-200 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#000"
              className="w-3 h-3"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>
          </button>
        </Link>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {preventive.apelido_equipamento}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {start}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {finish}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {preventive.nome_apelido}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 capitalize">
        {preventive.status === 'concluida' ? (
          <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
            {preventive.status}
          </span>
        ) : (
          <span className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-yellow-800">
            {preventive.status}
          </span>
        )}
      </td>
    </tr>
  );
}
