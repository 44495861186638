import React, { useState } from 'react';
import { UserCircleIcon } from '@heroicons/react/outline';
import TopBar from '../../components/TopBar';
import ProfileForm from './components/ProfileForm';
import AvatarForm from './components/AvatarForm';
import PasswordForm from './components/PasswordForm';
import SideBar from '../../components/SideBar';

const navigation = [
  { name: 'Usuário', href: '#', icon: UserCircleIcon, current: true },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function Count() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      <div className="min-h-full bg-gray-100">
        <SideBar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          active=""
        />

        <div className="lg:pl-64 flex flex-col flex-1">
          <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <div className="mt-4 px-4 lg:grid lg:grid-cols-12 lg:gap-x-5 lg:px-4">
            {/* <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:px-0 lg:col-span-2">
              <nav className="space-y-1">
                {navigation.map(item => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current
                        ? 'bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white'
                        : 'text-gray-900 hover:text-gray-900 hover:bg-gray-50',
                      'group rounded-md px-3 py-2 flex items-center text-sm font-medium',
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    <item.icon
                      className={classNames(
                        item.current
                          ? 'text-indigo-500 group-hover:text-indigo-500'
                          : 'text-gray-400 group-hover:text-gray-500',
                        'flex-shrink-0 -ml-1 mr-3 h-6 w-6',
                      )}
                      aria-hidden="true"
                    />
                    <span className="truncate">{item.name}</span>
                  </a>
                ))}
              </nav>
            </aside> */}

            <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-10">
              <ProfileForm />

              <PasswordForm />

              <AvatarForm />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
