/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import convertDateTime from '../../../functions/ConvertDateTime';
import { feedbackError } from '../../../functions/MessageFeedback';
import api from '../../../services/api';
import { IProposal } from './@types/IProposal';
import PartRequisition from './components/PartRequisition';

export default function ProposalPartReq() {
  const [id_os, setId_os] = useState<number>();
  const [proposal, setProposal] = useState<IProposal | null>();
  const [data_criacao, setData_criacao] = useState('');
  const [data_conclusao, setData_conclusao] = useState('');

  const params = new URLSearchParams(window.location.search);

  const id_proposta = params.get('id');

  useEffect(() => {
    if (id_proposta) getProposal(id_proposta);
  }, [id_proposta]);

  useEffect(() => {
    convertData();
  }, [proposal]);

  async function getProposal(id: string) {
    if (!id) return;

    try {
      const response = await api.get(`os/proposal/${id}`);

      setProposal(response.data);

      setId_os(response.data.os.id_os);
    } catch (err) {
      feedbackError(
        'Não foi possível carregar a proposta. Caso o erro persiste, contate o suporte.',
      );
    }
  }

  async function convertData() {
    const data_criacao = await convertDateTime(proposal?.os?.created_at);

    setData_criacao(data_criacao);

    if (proposal?.os?.data_saida) {
      const data_conclusao = await convertDateTime(proposal?.os?.data_saida);

      setData_conclusao(data_conclusao);
    }
  }

  return (
    <div className="min-h-full">
      {proposal?.status !== 'disabled' ? (
        <div className="lg:pl-64 flex flex-col flex-1">
          <main className="flex-1 pb-8">
            <div className="mt-4 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
              <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                <section aria-labelledby="applicant-information-title">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h2
                        id="applicant-information-title"
                        className="text-lg leading-6 font-medium text-gray-900"
                      >
                        {proposal?.os?.edificio}
                      </h2>
                      <p className="mt-1 max-w-2xl text-xs font-normal text-gray-900">
                        Elevador: {proposal?.os?.apelido_equipamento}
                      </p>
                      <p className="max-w-2xl text-xs text-gray-500">
                        OS: {proposal?.os?.id_os}
                      </p>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Solicitante
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {proposal?.os?.chamado
                              ? proposal?.os?.solicitante
                              : `Elite`}
                          </dd>
                        </div>
                        <div className="sm:col-span-1">
                          <dt className="text-sm font-medium text-gray-500">
                            Data de Solicitação
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {data_criacao}
                          </dd>
                        </div>
                        {proposal?.os?.chamado ? (
                          <>
                            <div className="sm:col-span-2">
                              <dt className="text-sm font-medium text-gray-500">
                                Defeito relatado
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {proposal?.os?.descricao}
                              </dd>
                            </div>
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Status do chamado
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900 capitalize">
                                {proposal?.os.status}
                              </dd>
                            </div>
                            {proposal?.os.status === 'concluido' ? (
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                  Data de conclusão
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {data_conclusao}
                                </dd>
                              </div>
                            ) : null}
                            {proposal?.os.status === 'concluido' ? (
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                  Técnico responsável
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {proposal?.os.nome_apelido}
                                </dd>
                              </div>
                            ) : null}
                            {proposal?.os.status === 'concluido' ? (
                              <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">
                                  Cliente atendeu
                                </dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  {proposal?.os.cliente_resp}
                                </dd>
                              </div>
                            ) : null}
                          </>
                        ) : null}
                        <div className="sm:col-span-2 border-t-2 pt-2">
                          <PartRequisition
                            id_os={id_os}
                            id_proposal={id_proposta}
                          />
                        </div>
                      </dl>
                    </div>
                    {/* <div>
                    <a
                      href="#"
                      className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                    >
                      ...
                    </a>
                  </div> */}
                  </div>
                </section>
              </div>
            </div>
          </main>
        </div>
      ) : (
        <div className="pt-8 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>

          <h3 className="mt-2 text-sm font-medium text-gray-900">
            Proposta não encontrada
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Houve um erro ao carregar esta proposta ou ela foi removida pela
            empresa.
          </p>
          <div className="mt-6"></div>
        </div>
      )}
    </div>
  );
}
