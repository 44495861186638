/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../functions/MessageFeedback';
import api from '../../../services/api';
import logoImg from '../../../assets/logo.svg';
import { Link } from 'react-router-dom';
import ValidateEmail from '../../../functions/ValidateEmail';

export default function ForgotPassword() {
  const [email, setEmail] = useState<string>();
  const [msgFeedback, setMsgFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const [mailSend, setMailSend] = useState(false);

  async function handleSubmit() {
    setLoading(true);

    const emailIsValid = ValidateEmail(email);

    if (!emailIsValid) {
      feedbackError('E-mail inválido');

      setLoading(false);

      return;
    }

    const data = {
      email,
    };

    try {
      const response = await api.post(`users/password/forgot`, data);

      setMailSend(true);

      if (response.status === 204) {
        setMsgFeedback(
          'Acesse o seu e-mail e siga as orientações para criar uma nova senha',
        );

        feedbackSuccess(
          'Agora você deve acessar seu e-mail para redefinir a sua senha',
        );
      }
    } catch (err) {
      feedbackError(
        'Erro ao solicitar nova senha. Verifique se você informou o endereço de e-mail que utiliza como login.',
      );
    }

    setLoading(false);
  }
  return (
    <div className="h-screen bg-white w-3/3 m-auto rounded-md flex flex-col justify-center py-8 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
        <img className="mx-auto h-32 w-auto flex" src={logoImg} alt="Logo" />
        <p className="mt-1 text-center text-base text-gray-500">
          Solicitar nova senha
        </p>
      </div>

      <div className="mt-1 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-2 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <div className="space-y-4">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-mail{' '}
                <span className="text-xs text-gray-400">
                  (o mesmo que você utiliza para realizar login)
                </span>
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="nope"
                  onChange={e => setEmail(e.target.value)}
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                />
              </div>
            </div>
            {msgFeedback ? (
              <div className="py-1 px-1 text-sm text-green-600 font-semibold">
                {msgFeedback}
              </div>
            ) : null}

            <div className="flex items-center justify-end">
              {!mailSend ? (
                <div className="text-sm">
                  <Link
                    to={{
                      pathname: '/signin',
                    }}
                  >
                    <a
                      href="#"
                      className="font-medium text-blue-900 hover:text-blue-600"
                    >
                      Lembrei a senha
                    </a>
                  </Link>
                </div>
              ) : null}
            </div>
            <div>
              {!mailSend ? (
                <button
                  disabled={loading}
                  onClick={() => handleSubmit()}
                  className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                >
                  Redefinir
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
