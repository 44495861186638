/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import { useLocation } from 'react-router-dom';
import api from '../../services/api';
import { IOs } from '../../@types/Os/IOs';
import Timeline from './components/Timeline';
import convertDateTime from '../../functions/ConvertDateTime';
import PartRequisition from './components/PartRequisition';

interface IState {
  state: {
    id: number;
  };
}

export default function Order() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [id_os, setId_os] = useState<number>();
  const [os, setOs] = useState<IOs>();
  const [data_criacao, setData_criacao] = useState('');
  const [data_conclusao, setData_conclusao] = useState('');

  const location: IState = useLocation();

  useEffect(() => {
    const { id } = location.state;

    setId_os(id);

    getOs(id);
  }, [location.state]);

  useEffect(() => {
    convertData();
  }, [os]);

  async function getOs(id_os: number) {
    if (!id_os) return;

    try {
      const response = await api.get(`os/${id_os}`);

      setOs(response.data);
    } catch (err) {
      setTimeout(() => {
        getOs(id_os);
      }, 2000);
    }
  }

  async function convertData() {
    const data_criacao = await convertDateTime(os?.created_at);

    setData_criacao(data_criacao);

    if (os?.data_saida) {
      const data_conclusao = await convertDateTime(os?.data_saida);

      setData_conclusao(data_conclusao);
    }
  }

  return (
    <div className="min-h-full bg-gray-100">
      <SideBar
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        active="orders"
      />

      <div className="lg:pl-64 flex flex-col flex-1">
        <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main className="flex-1 pb-8">
          <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="applicant-information-title"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      {os?.edificio}
                    </h2>
                    <p className="mt-1 max-w-2xl text-xs font-normal text-gray-900">
                      Elevador: {os?.apelido_equipamento}
                    </p>
                    <p className="max-w-2xl text-xs text-gray-500">
                      OS: {os?.id_os}
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Solicitante
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {os?.chamado ? os?.solicitante : `Elite`}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Data de Solicitação
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {data_criacao}
                        </dd>
                      </div>
                      {os?.chamado ? (
                        <>
                          <div className="sm:col-span-2">
                            <dt className="text-sm font-medium text-gray-500">
                              Defeito relatado
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {os?.descricao}
                            </dd>
                          </div>
                          <div className="sm:col-span-1">
                            <dt className="text-sm font-medium text-gray-500">
                              Status do chamado
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 capitalize">
                              {os.status}
                            </dd>
                          </div>
                          {os.status === 'concluido' ? (
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Data de conclusão
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {data_conclusao}
                              </dd>
                            </div>
                          ) : null}
                          {os.status === 'concluido' ? (
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Técnico responsável
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {os.nome_apelido}
                              </dd>
                            </div>
                          ) : null}
                          {os.status === 'concluido' ? (
                            <div className="sm:col-span-1">
                              <dt className="text-sm font-medium text-gray-500">
                                Cliente atendeu
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {os.cliente_resp}
                              </dd>
                            </div>
                          ) : null}
                        </>
                      ) : null}
                      <div className="sm:col-span-2 border-t-2 pt-2">
                        <PartRequisition id_os={id_os} />
                      </div>
                    </dl>
                  </div>
                  {/* <div>
                    <a
                      href="#"
                      className="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg"
                    >
                      ...
                    </a>
                  </div> */}
                </div>
              </section>
            </div>
            <Timeline os={os} />
          </div>
        </main>
      </div>
    </div>
  );
}
