import { IExtraService } from '../@types/ExtraService/IExtraService';
import api from '../services/api';

async function ListPreventiveCurrent(
  id_client: number,
): Promise<IExtraService[] | []> {
  try {
    const response = await api.get(
      `preventiva/current/bycustomer?id_cliente=${id_client}`,
    );

    return response.data;
  } catch (err) {
    return;
  }
}

export default ListPreventiveCurrent;
