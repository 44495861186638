import React, { useState } from 'react';
import { CheckCircleIcon, OfficeBuildingIcon } from '@heroicons/react/solid';
import SideBar from '../../components/SideBar';
import TopBar from '../../components/TopBar';
import ModalConfirmDanger from '../../components/ModalConfirmDanger';
import { useAuth } from '../../contexts/Auth';
import ListCards from './components/ListCards';
import { MdOutlineDangerous } from 'react-icons/md';
import api from '../../services/api';
import avatar_icon from '../../assets/avatar_icon.png';
import {
  feedbackError,
  feedbackSuccess,
} from '../../functions/MessageFeedback';
import EmptyClients from './components/EmptyClients';
import ListAttendances from './components/ListAttendances';

export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { user, client } = useAuth();

  async function newVerifyEmail() {
    try {
      await api.post(`users/validate/tryemail`);

      feedbackSuccess(
        'Enviamos um e-mail com um link de confirmação para você.',
        5,
      );
    } catch (err) {
      feedbackError(
        'Ocorreu um erro ao solicitar a validação. Se o problema persistir, entre em contato com o suporte.',
        8,
      );
    }
  }

  return (
    <>
      <div className="h-full bg-gray-100">
        <ModalConfirmDanger
          setVisible={false}
          title="Confirma a exclusão?"
          text="Você tem certeza que deseja excluir?"
          txtbutton="Excluir"
        />

        <SideBar
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          active="dashboard"
        />

        <div className="lg:pl-64 flex flex-col flex-1">
          <TopBar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

          <main className="flex-1 pb-8">
            <div className="bg-white shadow">
              <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
                <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                  <div className="flex-1 min-w-0">
                    <div className="flex items-center">
                      <div>
                        <div className="flex items-center">
                          <img
                            className="h-16 w-16 rounded-full sm:hidden"
                            src={
                              user?.avatar_url ? user.avatar_url : avatar_icon
                            }
                            alt="Avatar"
                          />
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                            Olá, {user.first_name}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-4 sm:flex-row sm:flex-wrap">
                          <dt className="sr-only">Edifício</dt>
                          <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                            <OfficeBuildingIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            {client?.edificio ? (
                              client?.edificio
                            ) : (
                              <span>Nenhum Condomínio Selecionado</span>
                            )}
                          </dd>
                          <dt className="sr-only">Status do usuário</dt>
                          <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                            {user?.email_verified ? (
                              <>
                                <CheckCircleIcon
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400"
                                  aria-hidden="true"
                                />
                                Usuário Verificado
                              </>
                            ) : (
                              <>
                                <MdOutlineDangerous
                                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400"
                                  aria-hidden="true"
                                />
                                <a onClick={() => newVerifyEmail()}>
                                  <span className="text-gray-400">
                                    email não confirmado
                                  </span>
                                </a>
                              </>
                            )}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
                    {/* <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Abrir Chat
                    </button> */}
                    {/* <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      Solicitar Atendimento Técnico
                    </button> */}
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8">
              {client?.id_cliente ? <ListCards /> : <EmptyClients />}
            </div>

            {client?.id_cliente ? <ListAttendances /> : null}
          </main>
        </div>
      </div>
    </>
  );
}
