/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../../contexts/Auth';
import {
  feedbackError,
  feedbackSuccess,
  feedbackWarning,
} from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';

export default function ProfileForm() {
  const [first_name, setFirst_name] = useState<string | null>(null);
  const [last_name, setLast_name] = useState<string | null>(null);
  const [cpf, setCpf] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const { getProfile, user } = useAuth();

  useEffect(() => {
    (async () => {
      getProfile();
    })();
  }, []);

  async function handleProfile() {
    setLoading(true);

    interface IData {
      first_name?: string;
      last_name?: string;
      cpf?: string;
      email?: string;
    }

    const data: Partial<IData> = {};

    if (first_name && first_name !== user.first_name)
      data.first_name = first_name;

    if (last_name && last_name !== user.last_name) data.last_name = last_name;

    if (cpf && cpf !== user.cpf) data.cpf = cpf;

    if (email && email !== user.email) data.email = email;

    try {
      await api.put('users/profile', data);

      await getProfile();

      feedbackSuccess('Dados atualizados');

      if (email && email !== user.email)
        feedbackWarning(
          'ATENÇÃO: Seu e-mail será atualizado após confirmá-lo, clicando no link que enviamos para você.',
          8,
        );
    } catch {
      feedbackError('Ocorreu um erro');
    }

    setLoading(false);

    history.push('/dashboard');
  }

  return (
    <>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Geral
            </h3>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="first_name"
                className="block text-sm font-medium text-gray-700"
              >
                Nome
              </label>
              <input
                type="text"
                name="first_name"
                id="first_name"
                defaultValue={user?.first_name}
                autoComplete="nome"
                onChange={e => setFirst_name(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="last_name"
                className="block text-sm font-medium text-gray-700"
              >
                Sobrenome
              </label>
              <input
                type="text"
                name="last_name"
                id="last_name"
                defaultValue={user?.last_name}
                autoComplete="sobrenome"
                onChange={e => setLast_name(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="cpf"
                className="block text-sm font-medium text-gray-700"
              >
                CPF
              </label>
              <input
                type="text"
                name="cpf"
                id="cpf"
                defaultValue={user?.cpf}
                autoComplete="cpf"
                onChange={e => setCpf(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="col-span-6 sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                E-mail{' '}
                <span className="text-xs text-gray-400">
                  (Usado ao fazer login)
                </span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                defaultValue={user?.email}
                autoComplete="email"
                onChange={e => setEmail(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            disabled={loading}
            onClick={() => handleProfile()}
            className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Salvar
          </button>
        </div>
      </div>
    </>
  );
}
