import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import api from '../../../../services/api';
import ValidateForcePassword from '../../../public/ResetPassword/components/ValidateForcePassword';

export default function PasswordForm() {
  const [old_password, setOld_password] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [forcePasswordCheck, setForcePasswordCheck] = useState(false);
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  async function handleSubmit() {
    if (!password1 || !password2) {
      feedbackError(
        'Você precisa escolher uma nova senha e confirmá-la no campo ao lado',
      );
      return;
    }

    if (!old_password) {
      feedbackError('Você precisa digitar a senha atual');
      return;
    }

    if (password1 !== password2) {
      feedbackError('As senhas digitadas não coincidem');
      return;
    }

    if (!forcePasswordCheck) {
      feedbackError('Senha pouco segura');
      return;
    }

    setLoading(true);

    const data = {
      old_password: old_password,
      password: password1,
    };

    try {
      await api.put(`users/profile`, data);

      feedbackSuccess('Dados atualizados.');
    } catch (err) {
      if (err.response.data?.message === 'Old password does not match') {
        feedbackError('A senha atual está incorreta');
      } else {
        feedbackError('Ocorreu um erro.');
      }
    }

    setLoading(false);

    history.push('/dashboard');
  }

  return (
    <>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Senha de Acesso
            </h3>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-6 lg:col-span-2">
              <label
                htmlFor="old_password"
                className="block text-sm font-medium text-gray-700"
              >
                Senha atual
              </label>
              <input
                type="password"
                name="old_password"
                id="old_password"
                onChange={e => setOld_password(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                htmlFor="password1"
                className="block text-sm font-medium text-gray-700"
              >
                Nova Senha
              </label>
              <input
                type="password"
                name="password1"
                id="password1"
                onChange={e => setPassword1(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
              {password1 ? (
                <ValidateForcePassword
                  password={password1}
                  setForcePasswordCheck={setForcePasswordCheck}
                />
              ) : null}
            </div>

            <div className="col-span-6 sm:col-span-3 lg:col-span-2">
              <label
                htmlFor="password2"
                className="block text-sm font-medium text-gray-700"
              >
                Repita a Nova Senha
              </label>
              <input
                type="password"
                name="password2"
                id="password2"
                onChange={e => setPassword2(e.target.value)}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            disabled={loading}
            onClick={() => handleSubmit()}
            className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Salvar
          </button>
        </div>
      </div>
    </>
  );
}
