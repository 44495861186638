/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { IClientsList } from '../../../../@types/User/IClientsList';
import { RadioGroup } from '@headlessui/react';
import { IClient } from '../../../../@types/Client/IClient';
import api from '../../../../services/api';
import { LoadingOutlined } from '@ant-design/icons';
import {
  feedbackError,
  feedbackSuccess,
} from '../../../../functions/MessageFeedback';
import { TrashIcon } from '@heroicons/react/outline';
import { Tooltip } from 'antd';
import ConfirmDialog from '../../../../components/ConfirmDialog';

interface IProps {
  client: IClientsList;
  index: number;
  clientsList: IClientsList[];
  setSelected(selected: number): void;
  getList(): void;
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function RowList(props: IProps) {
  const [dataCustomer, setDataCustomer] = useState<IClient>();
  const [loading, setLoading] = useState(false);

  const { client, index, clientsList, setSelected, getList } = props;

  useEffect(() => {
    getCustomer();
  }, [props]);

  async function getCustomer() {
    try {
      const response = await api.get(`client/${client.id_client}`);

      setDataCustomer(response.data);
    } catch (err) {
      return;
    }
  }

  async function handleDelete(id: number) {
    if (!id) return;

    setLoading(true);

    try {
      await api.delete(`pivotUserClient/${id}`);

      feedbackSuccess('Condomínio desvinculado de seu usuário');

      getList();
    } catch (error) {
      feedbackError('Ocorreu um erro ao desvincular');
    }

    setLoading(false);
  }

  return (
    <RadioGroup.Option
      key={client.id}
      value={client?.id_client}
      onChange={e => setSelected(e)}
      className={({ checked }) =>
        classNames(
          index === 0 ? 'rounded-tl-md rounded-tr-md' : '',
          index === clientsList.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
          checked ? 'bg-blue-50 border-blue-200 z-10' : 'border-gray-200',
          'relative border p-4 flex flex-col cursor-pointer md:pl-4 md:pr-6 md:grid md:grid-cols-3 focus:outline-none',
        )
      }
    >
      {({ active, checked }) => (
        <>
          <div className="flex items-center text-sm">
            <span
              className={classNames(
                checked
                  ? 'bg-blue-600 border-transparent'
                  : 'bg-white border-gray-300',
                active ? 'ring-2 ring-offset-2 ring-blue-500' : '',
                'h-4 w-4 rounded-full border flex items-center justify-center',
              )}
              aria-hidden="true"
            >
              <span className="rounded-full bg-white w-1.5 h-1.5" />
            </span>
            <RadioGroup.Label
              as="span"
              className={classNames(
                checked ? 'text-blue-900' : 'text-gray-900',
                'ml-3 font-medium',
              )}
            >
              {dataCustomer?.edificio}
            </RadioGroup.Label>
          </div>
          <RadioGroup.Description className="ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-center">
            <span
              className={classNames(
                checked ? 'text-blue-900' : 'text-gray-900',
                'font-medium',
              )}
            >
              {client.type === 'admin' ? (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                  Administrador
                </span>
              ) : (
                <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-green-100 text-green-800">
                  Padrão
                </span>
              )}
            </span>
          </RadioGroup.Description>
          <RadioGroup.Description
            className={classNames(
              checked ? 'text-blue-700' : 'text-gray-500',
              'ml-6 pl-1 text-sm md:ml-0 md:pl-0 md:text-right',
            )}
          >
            <ConfirmDialog
              title="Atenção! Você tem certeza que deseja remover este condomínio de seu usuário?"
              onConfirm={() => handleDelete(client.id)}
            >
              <button
                disabled={loading}
                className="inline-flex items-center ml-1 px-2 py-2 border border-red-600 shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {loading ? (
                  <LoadingOutlined style={{ marginRight: 6 }} />
                ) : (
                  <Tooltip title="Desvincular">
                    <TrashIcon className="h-4 w-4" aria-hidden="true" />
                  </Tooltip>
                )}
              </button>
            </ConfirmDialog>
          </RadioGroup.Description>
        </>
      )}
    </RadioGroup.Option>
  );
}
