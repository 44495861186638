import React, { useState } from 'react';
import { useAuth } from '../../contexts/Auth';
import logoImg from '../../assets/logo.svg';
import { feedbackError } from '../../functions/MessageFeedback';
import Loading from '../../components/Loading';
import { Link } from 'react-router-dom';

export default function Signin() {
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();

  const { sign, loading } = useAuth();

  async function handleLogin() {
    if (!username || !password) {
      feedbackError('Preencha os dados de login');

      return;
    }

    try {
      await sign(username, password);
    } catch (err) {
      return;
    }
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className="h-screen bg-white w-3/3 m-auto rounded-md flex flex-col justify-center py-8 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col">
            <img
              className="mx-auto h-32 w-auto flex"
              src={logoImg}
              alt="Logo"
            />
            <h2 className="mt-1 text-center text-3xl font-extrabold text-gray-700">
              Acessar Conta
            </h2>
            <p className="mt-1 text-center text-xs text-gray-500">
              Ou entre em contato com a empresa responsável
            </p>
          </div>

          <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
              <div className="space-y-6">
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    E-mail
                  </label>
                  <div className="mt-1">
                    <input
                      id="username"
                      name="username"
                      type="email"
                      autoComplete="email"
                      onChange={e => setUsername(e.target.value)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Senha
                  </label>
                  <div className="mt-1">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      onChange={e => setPassword(e.target.value)}
                      onKeyPress={event => {
                        if (event.key === 'Enter') {
                          handleLogin();
                        }
                      }}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="text-sm">
                    <Link
                      to={{
                        pathname: '/forgotpassword',
                      }}
                    >
                      <a
                        href="#"
                        className="font-medium text-blue-900 hover:text-blue-600"
                      >
                        Esqueci a senha
                      </a>
                    </Link>
                  </div>
                </div>

                <div>
                  <button
                    disabled={loading}
                    onClick={() => handleLogin()}
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-900 hover:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700"
                  >
                    Acessar
                  </button>
                </div>

                <div>
                  <Link
                    to={{
                      pathname: '/signup',
                    }}
                  >
                    <button className="w-full flex justify-center py-2 px-4 border border-blue-900 rounded-md text-sm font-light text-blue-900 bg-white hover:text-blue-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-700">
                      Criar Conta
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
